@import "bootstrap/ivideo-bootstrap";
@import "colors";
@import "fonts";
@import "variables";

html, body {
  height: 100%;
}

body {
  //background-attachment: fixed;
  background-image:
          //radial-gradient(circle at 20% 20%, rgba(#00f, .1), rgba(#fdfdf8, .3)),
          //radial-gradient(circle at 80% 80%, rgba($accent-color, .5), #fdfdf8)
          radial-gradient(circle at 20% 20%, rgba(#00f, .3), rgba(#fff, .1) 90vh),
          radial-gradient(circle at 80vw 80vh, rgba($accent-color, .5), #fff 90vh)
;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position:relative;
  &:before{
    $side: 100vw;
    content: '';
    display:none;
    position:absolute;
    width: $side;
    height: $side;
    background: $main-bg-color;
    transform-origin: 0 100%;
    transform: rotate(20deg);
    left: 30%;
    top: -$side;
  }
}

body:not([class^="player-page"]) > header {
  height: $header-height;
}

main {
  min-height: calc(100vh - (#{$header-height} + #{$footer-height}));
}

footer {
  height: $footer-height;
  color: #ddd;
  text-align: center;
}

svg.ivideo-logo {
  .st0 {
    fill: #333;
  }

  .st1 {
    fill: #0076ab;
  }
  .hand{
    fill: #0076ab;
    will-change: fill;
    transition:.2s all ease-in-out;
  }
  &:hover{
    .hand{
      fill:hsl(20, 90%, 45%);
    }

  }
}

.no-transitions *{
  transition: none !important;
}

.navbar{
  background-color: $main-bg-color;
}

body {
  background-color: #fff; // sorry Roberto
}

.player-page{
  background-color: rgba(240, 248, 255, 0.9);
}

.ivideo-video-card {
  border:none;
  width:200px;
  display: inline-block;
  margin: 10px;
  a{
    font-family: $main-font;
  }
}

.ivideo-hypervideo-card {
  line-height: 1.618rem;
  font-family: $main-font;
  width: 350px;
  display: inline-block;
  margin-right: 1.618rem;
  position: relative;
  overflow:hidden;
  padding-top:calc(#{$poster-height} + 1rem);
  padding-top:$poster-height;

  .poster{
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: $card-radius;
    top: 0;
    left: 0;
    right: 0;
    height: $poster-height;
  }
}

//.ivideo-no-video-image {
//  margin:10px 0;
//  background-image: url("../img/ivideo_logo_mano.svg");
//  background-repeat: no-repeat;
//  background-position: center;
//  background-size: contain;
//  height: 150px;
//}

.ivideo-video-thumbnail {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  height: 130px;
}

.ivideo-360-badge{
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  background-color: rgba(0,0,0,0.5);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 31px;
}

.ivideo-duration-badge {
  //background-image: linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0));
  //width: 100%;

  font-size: 1em;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-top-left-radius: 10px;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  text-align: right;
  font-family: $main-font;
  font-size: 0.75rem;
  background-color: rgba(0,0,0,0.5);
}

.ivideo-favourite-icon {
  position: absolute;
  left: 4px;
  bottom: 4px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
  padding: 4px;
  padding-bottom: 0;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}

.ivideo-favourite-icon > i {
  font-size: 30px;
}

.ivideo-play-icon {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
  padding: 4px;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.ivideo-play-icon > i {
  font-size: 50px;
}

.ivideo-info-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0,0,0,0.5);
  border-radius: 10px;
  padding: 4px;
  padding-bottom: 0;
  line-height: 18px;
  cursor: pointer;
  user-select: none;
}

.ivideo-info-icon > i {
  font-size: 30px;
}

.ivideo-video-card-detail {
  width: 100%;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $main-text-color;
  margin-top: .6rem;

  .material-icons{
    margin-right: 10px;
    vertical-align: middle;
  }
}

.ivideo-username-badge {
  font-size: 1em;
  color: $main-text-color;
}

.not-allowed {
  cursor: not-allowed;
}

.ivideo-btn{
  $btn-height:35px;
  //background-color:white;
  border:none;
  border-radius:$btn-radius;
  color:$btn-text-color;
  font-family:$main-font, sans-serif;
  font-size:.75rem;
  background-color: $main-bg-color;
  vertical-align: middle;
  display:inline-block;
  position:relative;
  color: white;

  &:hover{
    background:darken($main-bg-color, 5%);
    box-shadow: 0 0 3rem 1rem rgba(110, 0, 110, .175) !important;
  }

  &:active{
    background:darken($main-bg-color, 7%);
  }

  i.material-icons{
    position:absolute;
    left: 10px;
    top: -2px;
    line-height: $btn-height;
    margin:0;
    vertical-align:middle;
  }

  &.btn-secondary{
    background-color: $secondary;
  }

  &.btn-warning{
    background-color: $warning;
  }

  &.btn-danger{
    background-color: $danger;
  }

  &.ivideo-search-btn{
    border-radius: 10px;
  }

  &.ivideo-pagination-next-btn{
    border-radius: 0 10px 10px 0px !important;
    line-height: 1.2rem;

    &:hover{
      color:white;
    }
  }

  &.ivideo-pagination-prev-btn{
    border-radius: 10px 0px 0px 10px !important;
    line-height: 1.2rem;

    &:hover{
      color:white;
    }
  }
}

.ivideo-action-btn{
  user-select: none;
  cursor: pointer;
  border-radius: 50%;
  width:30px;
  height: 30px;
  &:hover{
    box-shadow: 0 0 3rem 1rem rgba(110, 0, 110, .175) !important;
  }

  i.material-icons{
    top:0px;
    left:3px;
    line-height: 30px;
  }
}

.ivideo-profile-btn {
  border-radius: 50%;
  padding: 6px;
  letter-spacing: 2px;
  font-weight: bold;
}

//TEAMS and AUTOCOMPLETE

.ivideo-container{
  background-color: $cards-background;
  border-radius: $card-radius;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

  table {
    text-align: left;
  }

}

.ivideo-input{
  background-color: rgba(255,255,255,0.6);
  border-radius: 10px;
}

.custom-file-label.ivideo-input{
  background-color: rgba(255,255,255,1);
}

.custom-file-label:after{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.autocomplete-wrapper{
  display: flex;
  flex-direction: column;
}

.autocomplete-form-wrapper{
  position:relative;
  ul {

    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    margin-top: 5px;
    padding: 0px;
    position: absolute;
    right: 0;
    left: 0;
    top: 33px;
    background-color: rgba(255,255,255,0.7);
    backdrop-filter: blur(4px);
    li:last-child {
      border-bottom: none;
    }
  }
}

.ivideo-team-users-table{

  margin-top: 15px;
}


#userSelectionChips {
  margin-top:10px;
  margin-bottom:10px;
  max-height:200px;
}

.ivideo-chips{
  .close{
    color:white;
    opacity: 0.7;
  }
  margin-right: 5px;
  margin-bottom:5px;
}

.ivideo-chips-text{
  margin-right:10px;
  color:white;
  line-height: 1.35rem;
}

#autoComplete{

  color: black;
  border-color: black;

  &:focus{
    color: black;
    border-color: black;
  }

  &:hover{
    color: black;
    border-color: black;
  }
}

.autoComplete_result {
  list-style-type: none;
  border-bottom: 1px solid rgba(0,0,0,0.5);
  padding: 5px 10px;
  text-align: left;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.2s ease;
}

.autoComplete_result::selection {
  color: rgba(#ffffff, 0);
  background-color: rgba(#ffffff, 0);
}

.autoComplete_result:hover {
  cursor: pointer;
  background-color: rgba(0, 118, 171, 0.4);
}

.autoComplete_selected {
  cursor: pointer;
  background-color: rgba(0, 118, 171, 0.4);
}

.autoComplete_highlighted {
  color: $primary;
  font-weight: 700;
  text-decoration: underline;
}

.modal-content{
  border-radius: $card-radius;
}

#addUserTeamModal{
  .ivideo-btn{
    position: initial;
  }
}

.ivideo-search-input{
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.ivideo-sort-wrapper{
  position:relative;

  i.material-icons {
    position:absolute;
    top:0;
  }
}

.ivideo-user-preview{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 300px;
}

.ivideo-tab{

  color: #fff;
  >.nav-link{
    background-color: transparent;
    border-radius: $tabs-radius $tabs-radius 3px 3px;
  }

  >.nav-link:not(.active){
    border-top: 1px solid white;
    border-right: 1px solid white;
    border-left: 1px solid white;
    border-radius: $tabs-radius $tabs-radius 0px 0px;
    &:hover{
      box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    }
  }

  >.nav-link.active{
    background-color: $main-bg-color;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }

}

.visibility-icon{
  color: #004f73;
}

.ivideo-hypervideo-title{
  display:inline;
  font-size: 1.2em;
  font-weight: 500
}

.shared-link{
  color:white;
  height: 50px;
  line-height: 30px;
  margin-top: 5px;
}

.with-icon{
  padding-left:40px;
}

.ivideo-no-left-padding{
  padding-left:0px;
}

.special-access-section{
  font-weight: bold;
  font-size: 1rem;
}

.min-width-50{
  min-width: 50px;
}

.file-upload-drag-area {
  border: 3px dashed darkgrey;
  height: 32rem;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.drag-area-over {
  border: 3px dashed darkorange;
}

.drag-area-success {
  border: 3px dashed $success;
}

.emoji-preview {
  height:30px;
  width: 30px;
  margin: 2px 6px;
}

.hypervideo-list-container{
  justify-content: flex-start;
}

.ivideo-tab-handler-btn {
  margin-top: 20px;
}

.dropdown-without-icon::after {
  content: none !important;
}

.is-clickable {
  cursor: pointer;
}

.help-indicator-icon {
  font-size: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.max-height-30 {
  max-height: 30px;
}