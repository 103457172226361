@import 'ivideo-bootstrap-variables';
@import "../../node_modules/bootstrap/scss/bootstrap";

// *********************************************************************************************************************
// skin
legend{
  font-size:1.2rem;
}

$focus-color: lighten($ivideo_primary, 10%);//hsla(140, 40%, 60%, 1);

.inputwrapper {

}

input[type=text], input[type=password] {
  background-image: linear-gradient(to right, $focus-color, $focus-color);
  background-size: 0 2px;
  background-position: left bottom;
  background-repeat: no-repeat;
  transition: .3s all ease-in-out;

  &:focus {
    outline:none;
    background-size: 100% 2px;
  }
}

.progress {
  height: 1.4rem;
  width: 100%;
  max-width: 40rem;
}

